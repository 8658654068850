import { gsap, Power3 } from 'gsap';
import { initCookieBanner } from './cookies';

// loading placeholder
document.addEventListener('DOMContentLoaded', function (e) {
  gsap.set('.page-content', { display: 'block' });
  gsap.to('.loading-placeholder', {
    duration: 0.5,
    opacity: 0,
    delay: 0.3,
    ease: Power3.easeOut,
    onComplete: function () {
      gsap.set('.loading-placeholder', { pointerEvents: 'none' });
    },
  });
  gsap.to('.container-content', {
    duration: 0.5,
    opacity: 1,
    delay: 6,

    ease: Power3.easeOut,
  });
  initCookieBanner();
});

const navigation = document.querySelector('.navigation');
const sandwich = document.querySelector('.navigation__sandwich');

sandwich.addEventListener('click', () => {
  navigation.classList.toggle('navigation--open');
});

const btnOpen = document.querySelector('.btn-open');
const btnClose = document.querySelector('.btn-close');
const overlay = document.querySelector('.overlay');

if (btnOpen) btnOpen.addEventListener('click', openOverlay);

if (btnOpen) btnClose.addEventListener('click', closeOverlay);

function openOverlay() {
  overlay.classList.add('overlay--open');
}

function closeOverlay() {
  overlay.classList.remove('overlay--open');
}

const ticker = document.querySelector('.container-content-ticker');

// if (ticker) ticker.addEventListener('click', openEditMode)

// function openEditMode() {
//     ticker.classList.toggle('container-content-ticker__list')
// }

if (window.sitecakeGlobals || sitecakeGlobals.editMode == true) {
  ticker.classList.add('container-content-ticker__list');
}

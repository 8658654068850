const storageKey = 'cookies-accepted'

export function initCookieBanner() {
  const timeout = ['/', '/index.html'].includes(window.location.pathname) ? 4500 : 0

  const hasAcceptedCookies = localStorage.getItem(storageKey)

  if (hasAcceptedCookies === 'true') return
  setTimeout(() => {
    const cookieElement = document.querySelector('.cookies')
    cookieElement.style.display = ''

    cookieElement.querySelector('button').addEventListener('click', () => {
      cookieElement.style.display = 'none'
      localStorage.setItem(storageKey, 'true')
    })
  }, timeout)
}
